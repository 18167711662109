import { Translate } from 'next-translate';
import { TypoXXSBold } from '@atoms/Typos';
import Link from '@components/Link';
import PromoBadge from '@components/PromoBadge';
import { GACHA_CABINET_CODE_20250321 } from '@features/gachas/constants';
import { useGetGachaCabinets } from '@features/gachas/hooks/useGetGachaCabinets';
import { useAppSelector } from '@hooks/useAppSelector';
import { useNeighbor } from '@hooks/useNeighbor';
import { useIsJapanUser } from '@utils/hooks';
import routes from '@utils/routes';
import styles from './css';

type Props = {
  t: Translate;
};

const HeaderLink = ({ t }: Props) => {
  const { gachaCabinets, pendingBonusCoins } = useAppSelector((state) => ({
    gachaCabinets: state.gachaCabinet.gachaCabinets,
    pendingBonusCoins: state.pendingBonusCoins,
  }));
  const isJapanUser = useIsJapanUser();
  const { isBasicPlan } = useNeighbor();
  useGetGachaCabinets();

  const showGachaLink = gachaCabinets.some(
    (gachaCabinet) =>
      gachaCabinet?.status?.active && gachaCabinet?.status?.eligible
  );
  const trialGachaCabinet = gachaCabinets.find(
    (gachaCabinet) => gachaCabinet.code === GACHA_CABINET_CODE_20250321
  );
  const isTrialGachaActiveAndEligible =
    trialGachaCabinet?.status?.active &&
    trialGachaCabinet?.status?.eligible &&
    (trialGachaCabinet?.gacha_ticket?.count ?? 0) > 0;

  const isClaimCoinShown = pendingBonusCoins?.pending_bonus_coins;

  const showUpgradeLink = isJapanUser && isBasicPlan;

  if (showGachaLink) {
    return (
      <Link className="header-link" href={routes.gachas}>
        <TypoXXSBold color="primary">ガチャ特設ページへ</TypoXXSBold>
        {!isClaimCoinShown && isTrialGachaActiveAndEligible && (
          <PromoBadge isFloating text="おためしガチャ実施中" />
        )}
        <style jsx>{styles}</style>
      </Link>
    );
  }
  if (showUpgradeLink) {
    return (
      <Link className="header-link" href={routes.upgradePlan}>
        <TypoXXSBold color="primary">{t('upgradePlan')}</TypoXXSBold>
        <style jsx>{styles}</style>
      </Link>
    );
  }
  return null;
};

export default HeaderLink;
