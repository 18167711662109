import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { getGachaCabinets } from '@redux/modules/gachaCabinet';
import { LANG_LOCALE } from '@utils/types';
import { isEmpty } from '@utils/utils';

export const useGetGachaCabinets = () => {
  const { locale } = useRouter() as { locale: LANG_LOCALE };
  const { authUser } = useAppSelector((state) => ({
    authUser: state.authUser,
  }));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isEmpty(authUser)) {
      dispatch(getGachaCabinets(locale, authUser));
    }
  }, [dispatch, locale, authUser]);
};
