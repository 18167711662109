export const GACHA_CABINET_CODE_20250305 = '20250305_ja_gacha_cab1';
export const GACHA_CABINET_CODE_20250321 = '20250321_ja_gacha_cab1';
export const GACHA_TOPIC_ID = {
  [GACHA_CABINET_CODE_20250305]: 22410,
  [GACHA_CABINET_CODE_20250321]: 22751,
};
export const CAMPAIGN_DIALOG_TITLE = {
  [GACHA_CABINET_CODE_20250305]: '「ワクワク空旅ガチャ」開催中！',

  [GACHA_CABINET_CODE_20250321]: '「おためしガチャ」開催中！',
};
