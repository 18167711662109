import { BREAKPOINT } from '@utils/constants';
import MediaQuery from '../MediaQuery';

const DesktopL = ({ children }) => (
  <MediaQuery media={`(min-width: ${BREAKPOINT.desktopL + 1}px)`}>
    {children}
  </MediaQuery>
);

export default DesktopL;
