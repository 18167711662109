import css from 'styled-jsx/css';
import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 4px;
    align-items: flex-start;
    width: 100%;
    max-width: 410px;

    @media screen and (max-width: ${BREAKPOINT.desktopL}px) {
      &.not-subscribed {
        gap: 1.5rem;
      }
    }

    .flight-nav {
      position: relative;
      :global(.campaign-badge-desktop) {
        position: absolute;
        right: -21px;
        animation: floating-y 0.8s ease-in-out infinite alternate-reverse;
      }
      :global(.campaign-badge-mobile) {
        position: absolute;
        right: -21px;
        top: -27px;
        animation: floating-y 0.8s ease-in-out infinite alternate-reverse;
      }
    }
    @keyframes floating-y {
      0% {
        transform: translateY(-10%);
      }
      100% {
        transform: translateY(10%);
      }
    }

    :global(path) {
      fill: ${COLORS.black600};
    }
    :global(.active path) {
      fill: ${COLORS.primary};
    }

    :global(.icons-inbox) {
      position: relative;
      :global(.unread-badge) {
        position: absolute;
        top: -3px;
        left: 29px;
      }
    }
  }
`;

export default styles;
