import classNames from 'classnames';
import IconCoinStroke from '@assets/icon-coin.svg';
import IconColoredCoin from '@assets/new/icon-colored-coin.svg';
import { SVG_SIZES } from '@utils/constants';
import styles from './css';

type IconProps = {
  color?:
    | 'black500'
    | 'black600'
    | 'brown'
    | 'gray'
    | 'purple'
    | 'red'
    | 'white';
  disabled?: boolean;
  size?: 'lg' | 'md' | 'ml' | 'sm' | 'xs' | 'xxs';
  withFill?: boolean;
};

const IconCoin = ({
  color,
  disabled = false,
  size = 'md',
  withFill = true,
}: IconProps = {}) => {
  const style = {
    height: SVG_SIZES[size],
    width: SVG_SIZES[size],
  };

  return (
    <>
      {withFill ? (
        <IconColoredCoin
          className={classNames(
            { [`color-${color}`]: color },
            { disabled },
            'icon-coin with-fill'
          )}
          style={style}
        />
      ) : (
        <IconCoinStroke
          className={classNames({ [`color-${color}`]: color }, 'icon-coin')}
          style={style}
        />
      )}
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default IconCoin;
