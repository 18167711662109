import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .unread-badge {
    display: inline-block;
    border-radius: 50px;
    box-shadow: 0 0 0 1px ${COLORS.white};
    background-color: ${COLORS.red500};
    padding: 2px;

    .unread-badge-wrapper {
      display: block;
      min-width: 12px;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      color: ${COLORS.white};
      text-align: center;
    }
  }
`;

export default styles;
