import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  :global(.color-pink.icon-user) {
    :global(path:first-child) {
      fill: ${COLORS.pink200};
      opacity: 1;
    }
  }
  :global(.color-blue.icon-user) {
    :global(path:first-child) {
      fill: ${COLORS.blue200};
      opacity: 1;
    }
  }
  :global(.color-primary-border.icon-user) {
    :global(path:first-child) {
      display: none;
    }
    :global(path) {
      stroke: ${COLORS.primary};
    }
  }
  :global(.color-brown.icon-user) {
    :global(path) {
      fill: transparent;
      stroke: ${COLORS.brown500};
    }
    :global(path:first-child) {
      fill: ${COLORS.brown500};
      opacity: 0.16;
    }
  }
  :global(.color-purple.icon-user) {
    :global(path:first-child) {
      fill: ${COLORS.purple200};
      opacity: 1;
    }
  }
  :global(.color-gray.icon-user) {
    :global(path:first-child) {
      fill: ${COLORS.black900};
      opacity: 0.16;
    }
  }
  :global(.icon-user.no-fill) {
    :global(path:first-child) {
      opacity: 0;
    }
  }
  :global(.icon-user.color-gray) {
    :global(path) {
      fill: ${COLORS.black600};
    }
  }
  :global(.icon-user.color-blue) {
    :global(path) {
      fill: ${COLORS.primary};
    }
  }
  :global(.icon-user.color-white) {
    :global(path) {
      fill: ${COLORS.white};
    }
  }
`;

export default styles;
