import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .promo-badge {
    background-color: ${COLORS.pink500};
    width: max-content;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    position: absolute;
    &.floating {
      animation: floating-y 0.8s ease-in-out infinite alternate-reverse;
    }
    &:before {
      content: '';
      position: absolute;
      top: -16px;
      left: 58%;
      margin-left: -15px;
      border: 8px solid transparent;
      border-bottom: 10px solid ${COLORS.pink500};
      z-index: 2;
    }

    @keyframes floating-y {
      0% {
        transform: translateY(-5%);
      }
      100% {
        transform: translateY(5%);
      }
    }
  }
`;

export default styles;
