import css from 'styled-jsx/css';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  :global(.header-link) {
    position: relative;
    @media screen and (min-width: ${BREAKPOINT.desktop}px) {
      margin-right: 12px;
    }
    :global(.typo) {
      white-space: nowrap;
    }
    :global(.promo-badge) {
      top: 26px;
    }
  }
`;

export default styles;
