import classNames from 'classnames';
import { TypoXXXSBold } from '@atoms/Typos';
import styles from './css';

type PromoBadgeProps = {
  isFloating?: boolean;
  text: string;
};

const PromoBadge = ({
  isFloating = false,
  text,
}: PromoBadgeProps): JSX.Element => {
  return (
    <div className={classNames('promo-badge', { floating: isFloating })}>
      <TypoXXXSBold color="white" text={text} />
      <style jsx>{styles}</style>
    </div>
  );
};

export default PromoBadge;
