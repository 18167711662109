import { useEffect, useState } from 'react';

type Props = {
  children: JSX.Element;
  media?: string;
};

const MediaQuery = ({ children, media }: Props) => {
  const [matches, setMatches] = useState<boolean>(false);

  const onMediaChange = ({ matches }: { matches: boolean }) => {
    setMatches(matches);
  };

  useEffect(() => {
    if (!media) return;
    const mediaQueryList = window.matchMedia(media);
    mediaQueryList.addEventListener('change', onMediaChange);
    setMatches(mediaQueryList.matches);
    return () => {
      mediaQueryList.removeEventListener('change', onMediaChange);
    };
  }, [media]);

  return matches ? children : null;
};

export default MediaQuery;
